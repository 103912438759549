/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import { Link } from "gatsby"

const NavLink = props => (
  <Box
    sx={{
      px: 3,
      py: [1, null, null, 0],
    }}
  >
    <Link
      {...props}
      partiallyActive={true}
      sx={{
        color: "text",
        textDecoration: "none",
        textTransform: "uppercase",
        letterSpacing: "caps",
        fontSize: [4, null, null, 2],
        ":hover, &[aria-current='page']": {
          color: "champagne",
        },
      }}
    >
      {props.children}
    </Link>
  </Box>
)

export default props => (
  <Flex
    as="nav"
    sx={{
      flex: 1,
      flexDirection: ["column", null, null, "row"],
      justifyContent: ["center", null, null, "flex-end"],
      alignItems: ["center", null, null, "flex-end"],
    }}
  >
    <NavLink to="/tuotteet">Tuotteet</NavLink>
    <NavLink to="/tuottajat">Tuottajat</NavLink>
    <NavLink to="/yhteystiedot">Yhteystiedot</NavLink>
  </Flex>
)
