/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const NavBar = props => (
  <Box
    sx={{
      position: "absolute",
      top: "12px",
      height: 2,
      backgroundColor: "black",
      width: "100%",
      borderRadius: 2,
      transition: "all .25s ease",

      "&:nth-of-type(2)": {
        transform: props.close ? "rotate(0deg)" : "rotate(45deg)",
      },
      "&:nth-of-type(3)": {
        transform: props.close ? "rotate(0deg)" : "rotate(-45deg)",
      },
      "&:first-of-type": {
        top: props.close ? "5px" : "12px",
        width: props.close ? "100%" : 0,
        left: props.close ? 0 : "50%",
      },
      "&:last-of-type": {
        top: props.close ? 19 : "12px",
        width: props.close ? "100%" : 0,
        left: props.close ? 0 : "50%",
      },
    }}
  />
)

const NavigationToggle = props => {
  return (
    <Box
      {...props}
      sx={{
        display: ["flex", null, null, "none"],
        width: 48,
        height: 48,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          cursor: "pointer",
          width: 24,
          height: 24,
          transition: ".4 ease-in-out",
        }}
      >
        <NavBar close={!props.close} />
        <NavBar close={!props.close} />
        <NavBar close={!props.close} />
        <NavBar close={!props.close} />
      </Box>
    </Box>
  )
}

export default NavigationToggle
