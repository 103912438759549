/** @jsx jsx */
import { Styled, Flex, Box, jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Global } from "@emotion/core"
import Header from "./header"
import Footer from "./footer"
import "../fonts/santevin.css"

const Layout = ({ children, fullWidth = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Styled.root>
      <Global
        styles={{
          "*": {
            boxSizing: "border-box",
          },
          body: {
            margin: 0,
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
          },
        }}
      />
      <Flex
        sx={{
          position: "relative",
          zIndex: 1,
          flexDirection: "column",
          minHeight: "100vh",
          bg: "white",
          mx: "auto",
        }}
      >
        <Header siteTitle={data.site.siteMetadata.title} />
        <Box
          as="main"
          sx={{
            maxWidth: fullWidth ? "100%" : 1440,
            width: "100%",
            mx: "auto",
            flex: "auto",
            p: [3, 4, null, 5],
          }}
        >
          {children}
        </Box>
        <Footer />
      </Flex>
    </Styled.root>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
