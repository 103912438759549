/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Navigation from "./navigation"
import NavigationToggle from "./navigationToggle"
import logo from "../images/logo.svg"

const MenuWrapper = props => (
  <Flex
    onFocus={e => {
      props.setMenu(true)
    }}
    sx={{
      position: ["fixed", null, null, "static"],
      top: 0,
      left: 0,
      bottom: 0,
      zIndex: 100,
      flexDirection: ["column", null, null, "row"],
      visibility: [props.open ? "visible" : "hidden", null, null, "visible"],
      opacity: [props.open ? 1 : 0, 1],
      width: "100%",
      bg: ["white", null, null, "transparent"],
      transition: "opacity .3s ease, visibility .3s ease",
      willChange: "opacity, visibility",
      alignItems: ["center", null, null, "flex-end"],
      justifyContent: ["space-between", null, null, "flex-end"],
      py: [4, null, null, 0],
    }}
  >
    {props.children}
  </Flex>
)

const Header = ({ siteTitle }) => {
  const [menu, setMenu] = useState(false)
  return (
    <Flex
      as="header"
      sx={{
        position: ["sticky", null, null, "relative"],
        top: 0,
        justifyContent: "space-between",
        alingItems: "flex-end",
        backgroundImage:
          "linear-gradient(180deg, #fff 0%, rgba(255,255,255,0) 100%)",
        zIndex: 100,
        px: [3, 4],
        py: [3, null, null, 4],
      }}
    >
      <Flex
        sx={{
          position: "relative",
          zIndex: 101,
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Link to="/" sx={{ display: "block" }}>
          <img
            src={logo}
            alt="Santevin"
            sx={{ display: "block", width: [128, 160] }}
          />
        </Link>
        <NavigationToggle
          close={menu}
          onClick={e => {
            setMenu(!menu)
            if (menu) return
          }}
        />
      </Flex>
      <MenuWrapper open={menu} setMenu={setMenu}>
        <Navigation />
        {/* <Flex sx={{ flex: "none", pl: 2 }}>
          <a
            href="https://www.instagram.com/santevin_finland_/"
            sx={{
              display: "block",
              ":hover": { "svg path": { fill: "champagne" } },
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              sx={{ display: "block", width: 24, height: 24 }}
            >
              <title>Instagram</title>
              <path
                d="M17.5 0h-11A6.51 6.51 0 000 6.5v11A6.51 6.51 0 006.5 24h11a6.51 6.51 0 006.5-6.5v-11A6.51 6.51 0 0017.5 0zM12 17.5a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm6.5-11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
                fill="#101010"
                fillRule="nonzero"
              />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/santevinfinland"
            sx={{
              display: "block",
              ml: 3,
              ":hover": { "svg path": { fill: "champagne" } },
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              sx={{ display: "block", width: 24, height: 24 }}
            >
              <title>Facebook</title>
              <path
                d="M19.55 14.56a.5.5 0 01-.5.44H17a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h6a1 1 0 001-1V1a1 1 0 00-1-1H1a1 1 0 00-1 1v22a1 1 0 001 1h11a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5V9.19a5.69 5.69 0 015.69-5.69h1.31a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-1.31a1.69 1.69 0 00-1.69 1.69v1.31a.5.5 0 00.5.5h2.43a.5.5 0 01.5.56l-.38 3z"
                fill="#101010"
                fillRule="nonzero"
              />
            </svg>{" "}
          </a>
        </Flex> */}
      </MenuWrapper>
    </Flex>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
