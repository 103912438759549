/** @jsx jsx */
import { jsx, Styled, Flex, Box, Footer } from "theme-ui"
import { At, FacebookLogo, InstagramLogo } from "phosphor-react"

export default props => (
  <Footer
    sx={{
      p: [3, 4],
      justifyContent: ["center", "space-between"],
      flexDirection: ["column", "row"],
      alignItems: "center",
      gap: 3,
    }}
  >
    <Box sx={{ order: [1, 0] }}>
      &copy; {new Date().getFullYear()} Santevin Oy
    </Box>
    <Flex
      sx={{
        order: [0, 1],
        gap: 2,
        alignItems: "center",
        svg: { display: "block" },
        a: { color: "text", display: "block", lineHeight: 1 },
      }}
    >
      <Styled.a href="mailto:santevin@santevin.fi" sx={{ pr: 1 }}>
        <At
          size={32}
          sx={{ color: "text", ":hover": { color: "champagne" } }}
        />
      </Styled.a>
      <Styled.a href="https://www.instagram.com/santevin_finland_/">
        <InstagramLogo
          size={32}
          sx={{ color: "text", ":hover": { color: "champagne" } }}
        />
      </Styled.a>
      <Styled.a href="https://www.facebook.com/santevinfinland">
        <FacebookLogo
          size={32}
          sx={{ color: "text", ":hover": { color: "champagne" } }}
        />
      </Styled.a>
    </Flex>
  </Footer>
)
